<template>
	<PageLayout :title="$t('disclaimer.title')" wrapper-class="select-wrapper">
		<div class="cashier-box">
			<div class="cashier-box__intro">
				<p>{{ $t('disclaimer.description') }}</p>
				<p>{{ $t('disclaimer.click-to-agree') }}</p>

				<p>
					<input type="checkbox" id="checkbox" v-model="accepted" />
					<label for="checkbox">{{ $t('disclaimer.yes-i-agree') }}</label>
					<span class="error">{{ error }}</span>
				</p>
			</div>
			<button type="button" class="button primary-button cashier-box__btn" @click="acceptDisclaimer">
				{{ $t('actions.proceed') }}
			</button>
		</div>
	</PageLayout>
</template>

<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../api';
import { extractErrorMessage } from '@/utils/errorHandling';

export default {
	name: 'Disclaimer',
	components: {
		PageLayout,
	},
	data() {
		return {
			accepted: false,
			error: null,
		};
	},
	methods: {
		async acceptDisclaimer() {
			this.error = null;
			if (this.accepted) {
				try {
					const data = await apiClient.acceptDepositDisclaimer();
          await this.$router.replace(this.$route.query.redirect ?? '/');
        } catch (e) {
					this.error = extractErrorMessage(e);
				}
			} else {
				this.error = this.$t('disclaimer.must-accept-before-deposit');
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.cashier-box {
	padding: $mobile-form-padding;
	display: flex;
	flex-direction: column;
	@include min-screen($md + 1) {
		padding: $form-padding;
	}

	&__intro {
		font-size: $title;
		@include min-screen($md + 1) {
			font-size: $heading;
		}

		p {
			text-align: left;
			font-size: $label;
			line-height: 1.5;
			margin: 0 0 15px 0;

			&:last-child {
				margin-bottom: 0;
			}

			.check-icon {
				width: 14px;
				height: 14px;
				margin-right: 6px;
			}
		}
	}

	&__btn {
		@include max-screen($md) {
			max-width: 100%;
		}
	}
}
</style>
